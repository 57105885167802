import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyAAeuee-PZuaf-NiZEdLli1RvNDxal5Vz4",
  authDomain: "dj-fadets-eb9ff.firebaseapp.com",
  databaseURL: "https://dj-fadets-eb9ff-default-rtdb.firebaseio.com",
  projectId: "dj-fadets-eb9ff",
  storageBucket: "dj-fadets-eb9ff.appspot.com",
  messagingSenderId: "715452199736",
  appId: "1:715452199736:web:12fee4934db7718d9447cd",
  measurementId: "G-J7BHE74ZNF"
};

firebase.initializeApp(config);

export default firebase.database();