import { render, staticRenderFns } from "./allcategory.vue?vue&type=template&id=d7796b4e"
import script from "./allcategory.vue?vue&type=script&lang=js"
export * from "./allcategory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./allcategory.vue?vue&type=custom&index=0&blockType=link&rel=preconnect&href=https%3A%2F%2Ffonts.googleapis.com"
if (typeof block0 === 'function') block0(component)
import block1 from "./allcategory.vue?vue&type=custom&index=1&blockType=link&rel=preconnect&href=https%3A%2F%2Ffonts.gstatic.com&crossorigin=true"
if (typeof block1 === 'function') block1(component)
import block2 from "./allcategory.vue?vue&type=custom&index=2&blockType=link&href=https%3A%2F%2Ffonts.googleapis.com%2Fcss2%3Ffamily%3DMr%2BDafoe%26display%3Dswap&rel=stylesheet"
if (typeof block2 === 'function') block2(component)

export default component.exports